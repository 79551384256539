@import '../../style/variables.scss';


.BannerBox{
    margin-top: 1rem;
}
.particleBox{
    background-color: $dark;
    overflow: hidden;
    height: 100vh;
    .particleContext{
        position: absolute;
        float: left;
        top: 0;
        right: 0;
        left: 0;
        margin-top: 5rem;
        .logoImg{
            height: 10rem;
            margin: auto;
            display: block;
            padding-top: 3rem;
        }
        .headingText{
            font-size: 1.5rem;
            color: $white;
            font-weight: 600;
            padding-top: 6rem;
        }
        .headText{
            font-size: 3rem;
            font-weight: 900;
            color: $white;
            padding-top: 1rem;
        }
    }
    
}