@import '../../style/variables.scss';

// .bannerBox{
//     .contentArea{
//         margin-top: 5rem;
//         .headText{
//             font-weight: 900;
//             padding-bottom: 1rem;
//         }
//     }
// }

.bannerImg{
    background-image: url(../../img/main.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    .logoImg{
        height: 10rem;
        margin: auto;
        display: block;
        padding-top: 3rem;
    }
    .headingText{
        font-size: 1.5rem;
        color: $white;
        font-weight: 600;
        padding-top: 6rem;
    }
    .headText{
        font-size: 3rem;
        font-weight: 900;
        color: $white;
        padding-top: 1rem;
    }
}


// banner2 style

.bannerBox{
    background-image: url(../../img/bannerbox.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40rem;
    overflow: hidden;
    .contextBox{
        background-size: 440px auto;
        position: relative;
        z-index: 2;
        padding: 3rem;
        text-align: justify;
        height: 100vh;
        .headText{
            padding-top: 5rem;
            font-weight: 800;
        }
        .conText{
            padding-top: 2rem;
        }
    }
    .contextBox:before{
        content: '';
        display: block;
        background: #fff;
        width: 50%;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
    .contextBox::after{
        content: '';
        display: block;
        background: #fff;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(220deg);
        z-index: -1;
        border-radius: 80px;
    }
}