@import './style/variables.scss';
@import url('https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap');

body{
  font-family: 'Lexend Deca', sans-serif;
  // overflow: hidden;
}
.bgMain{
  background-color: $yellow;
}
.primaryBtn{
  background-color: $yellow;
  color: $dark;
  border: $yellow;
}
.primaryBtn:hover{
  background-color: $dark;
  color: $white;
}

.ctaBtn{
  background-color: $red;
  color: $white;
  border: $red;
  margin: auto;
  display: block;
  width: 15rem;
  padding: 0.8rem;
  margin-top: 2rem;
  font-weight: 600;
}
.ctaBtn:hover{
  background-color: $red;
}
.ctaBtn2{
  background-color: $red;
  color: $white;
  border: $red;
  width: 15rem;
  padding: 0.8rem;
  margin-top: 2rem;
  font-weight: 600;
}
.ctaBtn2:hover{
  background-color: $red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
