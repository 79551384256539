@import url(https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'Lexend Deca', sans-serif; }

.bgMain {
  background-color: #fc0; }

.primaryBtn {
  background-color: #fc0;
  color: #000;
  border: #fc0; }

.primaryBtn:hover {
  background-color: #000;
  color: #fff; }

.ctaBtn {
  background-color: #ED4851;
  color: #fff;
  border: #ED4851;
  margin: auto;
  display: block;
  width: 15rem;
  padding: 0.8rem;
  margin-top: 2rem;
  font-weight: 600; }

.ctaBtn:hover {
  background-color: #ED4851; }

.ctaBtn2 {
  background-color: #ED4851;
  color: #fff;
  border: #ED4851;
  width: 15rem;
  padding: 0.8rem;
  margin-top: 2rem;
  font-weight: 600; }

.ctaBtn2:hover {
  background-color: #ED4851; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.bannerImg {
  background-image: url(/static/media/main.04bd5a90.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh; }
  .bannerImg .logoImg {
    height: 10rem;
    margin: auto;
    display: block;
    padding-top: 3rem; }
  .bannerImg .headingText {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    padding-top: 6rem; }
  .bannerImg .headText {
    font-size: 3rem;
    font-weight: 900;
    color: #fff;
    padding-top: 1rem; }

.bannerBox {
  background-image: url(/static/media/bannerbox.e2343910.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 40rem;
  overflow: hidden; }
  .bannerBox .contextBox {
    background-size: 440px auto;
    position: relative;
    z-index: 2;
    padding: 3rem;
    text-align: justify;
    height: 100vh; }
    .bannerBox .contextBox .headText {
      padding-top: 5rem;
      font-weight: 800; }
    .bannerBox .contextBox .conText {
      padding-top: 2rem; }
  .bannerBox .contextBox:before {
    content: '';
    display: block;
    background: #fff;
    width: 50%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }
  .bannerBox .contextBox::after {
    content: '';
    display: block;
    background: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: rotate(220deg);
            transform: rotate(220deg);
    z-index: -1;
    border-radius: 80px; }

.headerBox {
  box-shadow: 0 2px 18px 0 rgba(182, 178, 178, 0.5); }

.ctaBox {
  height: 20rem;
  opacity: 0.8;
  background-image: url(https://lh4.googleusercontent.com/proxy/UOhQwfclsAK8TnXZqoTkh9szHvYOJ3auDH07hZBZeVaaRWvzGaXpaYl60MfCRuW_S57gvzBw859pj5Xl2pW_GpfG8k2GhE9LUFNKwA=w3840-h2160-p-k-no-nd-mv);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .ctaBox .ctaContext {
    padding-top: 7rem;
    padding-bottom: 1rem; }
    .ctaBox .ctaContext .headText {
      color: #fc0;
      font-weight: 900;
      font-size: 3rem; }

.serviceArea {
  background-color: #333;
  overflow: hidden;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  height: 25rem; }
  .serviceArea .wdBox {
    background-image: url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/k-s26-maew-29-lyj3332-layout.jpg?auto=format&bg=F4F4F3&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-1.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=1000&s=012cf02e1633a15d5b18b06ece64629c);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0; }
  .serviceArea .wdmBox {
    background-image: url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/v203-tang-21-workspace_2.jpg?auto=format&bg=F4F4F3&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-1.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=1000&s=24983a39a1c881b0296c48757ef82187);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0; }
  .serviceArea .adBox {
    background-image: url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/k-p-v-achi-73129-lyj3553-3-appdevelopment.jpg?auto=format&bg=F4F4F3&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-1.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=800&s=340f875740a10490b5f8f46ea6a50914);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0; }
  .serviceArea .dmBox {
    background-image: url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/v211batch10-aum-170-socialmedia_4.jpg?auto=format&bg=F4F4F3&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-1.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=1000&s=ac96721e0e2b6a881cf6b1d7d47e83ec);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0; }
  .serviceArea .headText {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    padding-top: 22.5rem; }
  .serviceArea .serviceContext {
    background-color: rgba(237, 72, 81, 0.8);
    height: 25rem;
    color: #fff; }
  .serviceArea .serviceContext2 {
    background-color: rgba(255, 204, 0, 0.8);
    height: 25rem;
    color: #111; }

.testimonialArea {
  overflow: hidden;
  background: -webkit-linear-gradient(340deg, #ED4851 50%, #000 50%);
  background: linear-gradient(110deg, #ED4851 50%, #000 50%); }
  .testimonialArea .leftArea {
    height: 20rem; }
    .testimonialArea .leftArea .headArea {
      background: url(/static/media/look.03357c40.svg) center top no-repeat;
      background-size: 450px auto;
      max-width: 510px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      padding: 30px 0;
      margin-top: 5rem; }
      .testimonialArea .leftArea .headArea .headText {
        font-weight: 900;
        font-size: 2.5rem;
        color: #000; }
  .testimonialArea .rightArea {
    color: #fff;
    margin-top: 4rem; }
    .testimonialArea .rightArea .contextSlider {
      margin-top: 1rem;
      color: #fff; }
      .testimonialArea .rightArea .contextSlider .headText {
        text-align: center; }
      .testimonialArea .rightArea .contextSlider .conText {
        text-align: center;
        padding: 2rem; }

.carousel-control-prev-icon, .carousel-control-next-icon {
  display: none; }

.clientArea {
  margin-top: 5rem;
  overflow: hidden;
  margin-bottom: 5rem; }
  .clientArea .headArea {
    margin-top: 3rem;
    background: url(/static/media/look.03357c40.svg) center top no-repeat;
    height: 12rem;
    background-size: 450px auto;
    max-width: 510px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 30px 0; }
    .clientArea .headArea .headText {
      text-align: center;
      font-weight: 900;
      font-size: 3.5rem; }
    .clientArea .headArea .clientLogo {
      margin: auto;
      display: block;
      height: 8rem; }

.handArea {
  height: 30rem;
  background-color: #fff; }
  .handArea .mainText {
    text-align: center;
    font-size: 10rem;
    color: #000;
    text-transform: uppercase;
    font-weight: bolder;
    margin-top: 5rem;
    cursor: pointer;
    text-shadow: 1px 1px #fc0; }
  .handArea .border1 {
    border: 2px solid #fc0;
    width: 80%;
    margin: auto;
    display: block; }
  .handArea .border2 {
    border: 4px solid #fc0;
    width: 80%;
    margin: auto;
    display: block;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    letter-spacing: 0; }
  .handArea .border3 {
    border: 2px solid #fc0;
    width: 80%;
    margin: auto;
    display: block; }
  .handArea .mainText:hover {
    background-color: #fc0;
    -webkit-transition: 2s ease;
    transition: 2s ease;
    -webkit-transform: translate3d(0px, -27.156px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
            transform: translate3d(0px, -27.156px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); }
  .handArea .handImg {
    margin: auto;
    display: block;
    z-index: -1;
    margin-top: -3rem;
    height: 22rem; }

.footerArea {
  overflow: hidden;
  background-color: #000;
  height: 28rem; }
  .footerArea .footerBox {
    margin-top: 10rem;
    color: #fff; }
    .footerArea .footerBox .highliteText {
      text-align: justify;
      font-size: 1.2rem;
      padding-left: 2rem; }
    .footerArea .footerBox .aboutText {
      font-size: 0.9rem; }
    .footerArea .footerBox .mailText {
      text-align: center;
      font-weight: 500; }
      .footerArea .footerBox .mailText .arrowImg {
        height: 3rem;
        margin-right: 2rem;
        margin-top: 2rem; }
    .footerArea .footerBox .socialBox {
      margin: auto;
      display: block;
      text-align: right;
      margin-right: 1rem; }
      .footerArea .footerBox .socialBox .socialIcon {
        height: 2rem;
        padding-right: 1rem; }
    .footerArea .footerBox .copyText {
      font-size: 0.8rem;
      text-align: right;
      padding-right: 2rem; }
    .footerArea .footerBox .linkText {
      text-decoration: underline;
      cursor: pointer;
      line-height: 1.8rem; }

.BannerBox {
  margin-top: 1rem; }

.particleBox {
  background-color: #000;
  overflow: hidden;
  height: 100vh; }
  .particleBox .particleContext {
    position: absolute;
    float: left;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 5rem; }
    .particleBox .particleContext .logoImg {
      height: 10rem;
      margin: auto;
      display: block;
      padding-top: 3rem; }
    .particleBox .particleContext .headingText {
      font-size: 1.5rem;
      color: #fff;
      font-weight: 600;
      padding-top: 6rem; }
    .particleBox .particleContext .headText {
      font-size: 3rem;
      font-weight: 900;
      color: #fff;
      padding-top: 1rem; }

