@import '../../style/variables.scss';

.headerBox{
    box-shadow: 0 2px 18px 0 rgba(182,178,178, 0.5);
}

.ctaBox{
    height: 20rem;
    opacity: 0.8;
    background-image: url(https://lh4.googleusercontent.com/proxy/UOhQwfclsAK8TnXZqoTkh9szHvYOJ3auDH07hZBZeVaaRWvzGaXpaYl60MfCRuW_S57gvzBw859pj5Xl2pW_GpfG8k2GhE9LUFNKwA=w3840-h2160-p-k-no-nd-mv);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    .ctaContext{
        padding-top: 7rem;
        padding-bottom: 1rem;
        .headText{
            color: $yellow;
            font-weight: 900;
            font-size: 3rem;
        }
    }
    
}
.serviceArea{
    background-color: $darklite;
    overflow: hidden;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    height: 25rem;
    .wdBox{
        background-image: url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/k-s26-maew-29-lyj3332-layout.jpg?auto=format&bg=F4F4F3&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-1.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=1000&s=012cf02e1633a15d5b18b06ece64629c);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // height: 25rem;
        padding: 0;
    }
    .wdmBox{
        background-image: url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/v203-tang-21-workspace_2.jpg?auto=format&bg=F4F4F3&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-1.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=1000&s=24983a39a1c881b0296c48757ef82187);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // height: 25rem;
        padding: 0;
    }
    .adBox{
        background-image: url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/k-p-v-achi-73129-lyj3553-3-appdevelopment.jpg?auto=format&bg=F4F4F3&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-1.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=800&s=340f875740a10490b5f8f46ea6a50914);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // height: 25rem;
        padding: 0;
    }
    .dmBox{
        background-image: url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/v211batch10-aum-170-socialmedia_4.jpg?auto=format&bg=F4F4F3&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-1.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=1000&s=ac96721e0e2b6a881cf6b1d7d47e83ec);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // height: 25rem;
        padding: 0;
    }
    .headText{
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        padding-top: 22.5rem;
    }
    .serviceContext{
        // background-color: rgba(7, 7, 7, 0.9);
        background-color: rgba(237,72,81, 0.8);
        height: 25rem;
        color: #fff;
        
    }
    .serviceContext2{
        // background-color: rgba(255, 255, 255, 0.3);
        background-color: rgba(255,204,0, 0.8);
        height: 25rem;
        color: #111;
    }
    // .serviceContext:hover{
    //     background-color: rgba(237,72,81, 0.8);
    //     color: $white;
    // rgb(255,204,0)
    // }
}

.testimonialArea{
    overflow: hidden;
    background: linear-gradient(110deg,$red 50%, $dark 50%);
    .leftArea{
        height: 20rem;
        .headArea{
            background: url(../../img/look.svg) center top no-repeat;
            background-size: 450px auto;
            max-width: 510px;
            width: 100%;
            margin: 0 auto;
            text-align: center;
            padding: 30px 0;
            margin-top: 5rem;
            .headText{
                font-weight: 900;
                font-size: 2.5rem;
                color: $dark;
            }
        }
    }
    .rightArea{
        color: $white;
        margin-top: 4rem;
        .contextSlider{
            margin-top: 1rem;
            color: $white;
            .headText{
                text-align: center;
            }
            .conText{
                text-align: center;
                padding: 2rem;
            }
        }
    }
}
.carousel-control-prev-icon, .carousel-control-next-icon{
    display: none;
}

.clientArea{
    margin-top: 5rem;
    overflow: hidden;
    margin-bottom: 5rem;
    .headArea{
        margin-top: 3rem;
        background: url(../../img/look.svg) center top no-repeat;
        height: 12rem;
        background-size: 450px auto;
        max-width: 510px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 30px 0;
        .headText{
            text-align: center;
            font-weight: 900;
            font-size: 3.5rem;
        }
        .clientLogo{
            margin: auto;
            display: block;
            height: 8rem;
        }
    }
}

.handArea{
    height: 30rem;
    background-color: $white;
    .mainText{
        text-align: center;
        font-size: 10rem;
        color: $dark;
        text-transform: uppercase;
        font-weight: bolder;
        margin-top: 5rem;
        cursor: pointer;
        text-shadow: 1px 1px $yellow;
    }
    .border1{
        border: 2px solid $yellow;
        width: 80%;
        margin: auto;
        display: block;
    }
    .border2{
        border: 4px solid $yellow;
        width: 80%;
        margin: auto;
        display: block;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        letter-spacing: 0;
    }
    .border3{
        border: 2px solid $yellow;
        width: 80%;
        margin: auto;
        display: block;
    }
    .mainText:hover{
        background-color: $yellow;
        transition: 2s ease;
        transform: translate3d(0px, -27.156px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
    }
    .handImg{
        margin: auto;
        display: block;
        z-index: -1;
        margin-top: -3rem;
        height: 22rem;
    }
}

.footerArea{
    overflow: hidden;
    background-color: $dark;
    height: 28rem;
    .footerBox{
        margin-top: 10rem;
        color: $white;
        .highliteText{
            text-align: justify;
            font-size: 1.2rem;
            padding-left: 2rem;
        }
        .aboutText{
            font-size: 0.9rem;
        }
        .mailText{
            text-align: center;
            font-weight: 500;
            .arrowImg{
                height: 3rem;
                margin-right: 2rem;
                margin-top: 2rem;
            }
        }
        .socialBox{
            margin: auto;
            display: block;
            text-align: right;
            margin-right: 1rem;
            .socialIcon{
                height: 2rem;
                padding-right: 1rem;
            }
        }
        .copyText{
            font-size: 0.8rem;
            text-align: right;
            padding-right: 2rem;
        }
        .linkText{
            text-decoration: underline;
            cursor: pointer;
            line-height: 1.8rem;
        }
    }
}